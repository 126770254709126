<script>
export default {
  name: "DocumentSearchTender",
  created() {
    this.$router.push({
      name: "DocumentSearch",
      params: {
        module: "tender",
      },
    });
  },
};
</script>
